<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <div class="modal__box">
        <div class="close" @click="$emit('close-modal')">
          <img class="close-img" src="@/assets/images/icons/close.svg" alt="">
        </div>
        <div class="modal_body">
          <p class="modal__text">При повторной отправке данных пароль будет сброшен и заменён на новый.</p>
          <p class="modal__text">Будут использованы следующие данные для входа:</p>
          <div class="user_data">
            <p>
              <b>
                email:
              </b>
              {{ email }}
            </p>
            <p>
              <b>
                пароль:
              </b>
              {{ password }}
            </p>
          </div>
        </div>
      </div>
      <div class="btn-tools">
        <button @click="sendData" :disabled="isDisabled" class="btn btn-dark">Отправить</button>
        <button class="btn btn-accent" @click="$emit('close-modal')">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "@vue/runtime-core";

export default {
  name: "StaffChangePasswordModal",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup(props, {emit}) {
    const store = useStore();
    const isDisabled = ref(false);

    const sendData = () => {
      isDisabled.value = true;
      store.dispatch("users/resetStaffPassword", {id: props.id, password: props.password}).then(() => {
        isDisabled.value = false;
        emit("close-modal");
      }).catch(() => {
        isDisabled.value = false;
      });
    }

    return {
      sendData,
      isDisabled,
    }
  }
}
</script>

<style scoped lang="scss">

.modal-overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  overflow: auto;
}

.modal_body {
  padding: 25px 10px;
}

.user_data {
  padding: 35px 0;
  text-align: center;
}

@media only screen and (max-height: 570px) {
  .modal {
    margin-top: 0 !important;
  }
}

.modal {
  display: block !important;
  overflow-y: initial !important;
  position: relative;
  background-color: white;
  height: 350px;
  width: 900px;
  margin-top: 10%;
  padding: 20px 40px;

  &__box {
    text-align: left;
  }

  &__text {
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    @media (max-width: 715px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__btn {
    padding-top: 30px;
  }
}

.close {
  position: absolute;
  right: 49px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.btn-tools {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  @media (max-width: 715px) {
    flex-direction: column;
    row-gap: 10px;
  }
}

.btn-box-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 715px) {
  .modal {
    margin-top: 0;
    height: 100%;
  }
}
</style>